import "../css/team.css";
import { ThemeContext } from "../App";
import { useContext } from "react";
const Team = ({ data }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='container11'
      id='team'
      style={{
        backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
      }}>
      <h1 className='text-light about-title'>{data.title}</h1>
      <div className='line' style={{ backgroundColor: "white" }} />
      <div className='teamContainer row w-100'>
        {data.profiles.map((el) => {
          return (
            <div className='col-md-3 col-6'>
              <div className='memberInfo'>
                <img
                  src={el.profileImage}
                  className='profilePng'
                  alt='profile'
                />
                <p className='personInfo text-light'>{el.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Team;
