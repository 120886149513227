import "../css/about.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../App";
import { CgEditBlackPoint } from "react-icons/cg";
import { FaProjectDiagram } from "react-icons/fa";
const About = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='about-body d-flex flex-column align-items-center'
      id='about'>
      <div className='about-title text-center'>{data.title}</div>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='m-3'>{data.detailedTitle}</div>
      <div className='row pt-2 width-100'>
        <div
          className='about-img-container col-lg-6 col-md-12 p-5 p-lg-0'
          data-aos='fade-right'>
          <img alt='img' className='about-img' src={data.image} />
        </div>
        <div className='about-text col-lg-6 col-md-12 p-5' data-aos='fade-left'>
          <div className='m-3'>
            {data.lists.map((el) => {
              return (
                <div className='d-flex flex-row align-items-center my-1'>
                  <FaProjectDiagram size={25} color={theme[1]} />
                  <div className='li-lists mx-3'>{el}</div>
                </div>
              );
            })}
          </div>
          <div className='mini-title m-3 font-weight-bold'>{data.purpose}</div>
          <div data-aos='fade-left' className='about-text-one mx-3 my-4 '>
            {data.textOne}
          </div>
          <div className='mini-title mx-3 my-4' data-aos='fade-left'>
            {data.goal}
          </div>
          <div data-aos='fade-left' className='m-3'>
            {data.textTwo}
          </div>
        </div>
      </div>
      <div className='about-mini-title mt-5 text-center'>{data.titletwo}</div>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='row pt-2 width-100'>
        <div className='about-text col-lg-4 col-md-12 p-5' data-aos='fade-left'>
          <div
            data-aos='fade-right'
            className='about-text-one mx-3 my-4'>
            <CgEditBlackPoint size={25} color={theme[1]} />
            {" " + data.paragraphOne}
          </div>
          <div data-aos='fade-right' className='m-3'>
            <CgEditBlackPoint size={25} color={theme[1]} />
            {" " + data.paragraphTwo}
          </div>
          <div data-aos='fade-right' className='m-3'>
            <CgEditBlackPoint size={25} color={theme[1]} />
            {" " + data.paragraphFive}
          </div>
        </div>
        <div
          className='about-img-container col-lg-4 col-md-12 p-5 p-lg-0'
          data-aos='fade-up'>
          <img alt='img' className='about-img' src={data.paragraphImage} />
        </div>
        <div className='about-text col-lg-4 col-md-12 p-5' data-aos='fade-left'>
          <div
            data-aos='fade-left'
            className='about-text-one mx-3 my-4'>
            <CgEditBlackPoint size={25} color={theme[1]} />{" "}
            {" " + data.paragraphThree}
          </div>
          <div data-aos='fade-left' className='m-3'>
            <CgEditBlackPoint size={25} color={theme[1]} />{" "}
            {" " + data.paragraphFour}
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
