import "../css/gallery.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../App";
import { Image } from "./image";
const Gallery = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div className='container1' id='gallery'>
      <div className="about-title">{data.title}</div>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <p>{data.detailedTitle}</p>
      <div className='imageContainer row p-5'>
        {data.images.map((el, idx) => {
          return (
            <Image
              className=''
              title={data.titles?.length > idx ? data.titles[idx] : ""}
              image={el}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Gallery;
