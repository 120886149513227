import "../css/home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Home = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='home-body' id="home">
      <div
        className='home-text d-flex justify-content-center align-items-center flex-column'
        style={{ backgroundImage: "url(" + data.image + ")" }}>
        <div className="home-inner">
          <div className='home-title text-center'>{data.title}</div>
          <div className='home-paragraph text-center'>{data.paragraph}</div>
        </div>
      </div>
    </div>
  );
};

export default Home;
