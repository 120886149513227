import "../css/contactUs.css";
import { SiGmail, SiFacebook, SiGooglemaps } from "react-icons/si";
import { FaPhoneAlt } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../App";

const Contact = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='contact-container p-3 p-md-5'
      id='contact'
      style={{
        backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
      }}>
      <div className='row w-100'>
        <div className='logoContainer col-sm-4'>
          {/* <img alt='logo' className='logo' src={data.logo} /> */}
        </div>
        <div className='contactContainer col-md-5 col-sm-6'>
          <div className='iconText'>
            <SiGooglemaps size={25} className='icons' />
            <p className='w-75'>{data.location}</p>
          </div>
          <div className='iconText'>
            <SiGmail size={25} className='icons' />
            <p className='w-75'>{data.mail}</p>
          </div>
          <div className='iconText'>
            <FaPhoneAlt size={25} className='icons' />
            <p className='w-75'>{data.phoneNum}</p>
          </div>
          <div className='iconText'>
            <SiFacebook size={25} className='icons' />
            <a
              href={`${data.facebookAddress}`}
              target='blank'
              className='facebookLink w-75'>
              <p>Facebook page</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
