import React from "react";

export const Image = ({ title, image }) => {
  return (
    <div className='portfolio-item'>
      <div className="hover-bg">
        <div className="hover-text">
          <h4>{title}</h4>
        </div>
        <img data-aos='fade-up' src={image} className='' alt={title} />{" "}
      </div>
    </div>
  );
};
