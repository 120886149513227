import "../css/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../App";
import { SiFuturelearn } from "react-icons/si";
import { PiBooksBold } from "react-icons/pi";
import { LuLibrary } from "react-icons/lu";
import { HiMiniDevicePhoneMobile } from "react-icons/hi2";
const Services = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='services w-100 h-75 d-flex flex-column align-items-center'
      id='services'>
      <h1 className='about-title text-center'>{data.title}</h1>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='services-container w-100 h-100 row d-flex justify-content-center align-items-center'>
        <div
          className='service-card col-lg-2 col-sm-5  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
            <SiFuturelearn color={theme[1]} size={100} />
            <div className='text-center h-50'>{data.servicesFirst}</div>
          </div>
        </div>
        <div
          className='service-card col-lg-2 col-sm-5  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
            <PiBooksBold color={theme[1]} size={100} />
            <div className='text-center h-50'>{data.servicesSecond}</div>
            <a target="_blank" href="https://tomkobooks.com/">www.tomkobooks.com</a>
          </div>
        </div>
        <div
          className='service-card col-lg-2 col-sm-5  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
            <LuLibrary color={theme[1]} size={100} />
            <div className='text-center h-50'>{data.servicesThird}</div>
          </div>
        </div>
        <div
          className='service-card col-lg-2 col-sm-5  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
            <HiMiniDevicePhoneMobile color={theme[1]} size={100} />
            <div className='text-center h-50'>{data.servicesFourth}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
