import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Header from "./component/Header";
import Home from "./component/Home";
import About from "./component/About";
import Services from "./component/Services";
import Team from "./component/Team";
import Gallery from "./component/Gallery";
import Contact from "./component/ContactUs";
import data from "./data/data.json";
import { createContext, useState } from "react";
export const ThemeContext = createContext();
function App() {
  window.$ = window.jQuery = require("jquery");
  const [language, setLanguage] = useState(false);
  const [theme, setTheme] = useState([
    data.Theme.ThemeColor.colorOne,
    data.Theme.ThemeColor.colorTwo,
  ]);
  return (
    <ThemeContext.Provider value={{ language, setLanguage, theme, setTheme }}>
      <Header
        title={language ? data.English.Title : data.Mongolian.Title}
        data={language ? data.English.Header : data.Mongolian.Header}
      />
      <Home data={language ? data.English.Home : data.Mongolian.Home} />
      <About data={language ? data.English.About : data.Mongolian.About} />
      <Services
        data={language ? data.English.Services : data.Mongolian.Services}
      />
      <Team data={language ? data.English.Team : data.Mongolian.Team} />
      <Gallery
        data={language ? data.English.Gallery : data.Mongolian.Gallery}
      />
      <Contact
        data={language ? data.English.Contact : data.Mongolian.Contact}
      />
    </ThemeContext.Provider>
  );
}

export default App;
